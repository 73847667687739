<template>
  <!--
    The error-site (is view; has no component)
  -->
  <Portlet
    :sub-title="$t('errorView.cantFindPage')"
    title="404"
    icon="exclamation-triangle"
    class="error"
  >
    <p>{{ $t('not') }}</p>
  </Portlet>
</template>

<script>
export default {
  name: "Error",
  metaInfo () {
    return {
      title: this.$t('error')
    }
  }
}
</script>
